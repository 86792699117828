<template>
  <div class="policymatch">
    <nf-breadcrumb :breadList="[{ name: '知识产权' }, { name: '政策匹配' }]"></nf-breadcrumb>

    <div class="policymatch_content">
        <p class="title">智能政策匹配</p>
        <el-form ref="form" :model="form" :rules='rules' label-width="220px" >
            <el-form-item label="投资(额度)" prop="value1">
                <el-select v-model="form.value1" placeholder="请选择投资(额度)">
                    <el-option
                    v-for="item in select_data.options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="税收" prop="value2">
                <el-select v-model="form.value2" placeholder="请选择税收">
                    <el-option
                    v-for="item in select_data.options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产值" prop="value3">
                <el-select v-model="form.value3" placeholder="请选择产值">
                    <el-option
                    v-for="item in select_data.options3"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="融资额" prop="value4">
                <el-select v-model="form.value4" placeholder="请选择融资额">
                    <el-option
                    v-for="item in select_data.options4"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="地区" prop="proviceId">
                <villageAddressSelect @villageInfo="villageInfo" @proviceInfo="proviceInfo" :cityDistrictVillage='cityDistrictVillage'></villageAddressSelect>
            </el-form-item>
            <el-form-item label="你想通过政策" prop="value5">
                <el-select v-model="form.value5" placeholder="请选择你想通过政策">
                    <el-option
                    v-for="item in select_data.options5"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class='text-center'>
              <el-button type="primary" @click="onSubmit">一键匹配</el-button>
            </div>
        </el-form>
        <!-- 匹配结果 -->
        <div class='consultation' v-if='passShow'>
          <h3 class="newsTitle">匹配结果：</h3>
          <ul class='newsList'>
              <li @click="openLink(item.policyLink)" class='ellipsis' v-for='item in infoList' :key='item.policySort'>
                  {{item.policyTitle}}
                  <span class="newsData">{{ item.policyDate }}</span>
              </li>
          </ul>
        </div>
    </div>
  </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import villageAddressSelect from './_part/addressSelect';

const reqproviceId = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请选择城市列表'));
        return;
    }
    callback();
};

export default {
  name: 'policyMatch',
  components: {
    nfBreadcrumb,
    villageAddressSelect
  },
  data() {
    return {
      startTime: null,
      pageName: "知识产权>政策匹配(policyMatch.vue)",
      // 校验规则
      rules:{
        'value1': [
            { message: '请选择投资(额度)', required: true}
        ],
        'value2': [
            { message: '请选择税收', required: true}
        ],
        'value3': [
            { message: '请选择产值', required: true}
        ],
        'value4': [
            { message: '请选择融资额', required: true}
        ],
        'proviceId': [
            { validator: reqproviceId, required: true}
        ],
        'value5': [
            { message: '请选择你想通过政策', required: true}
        ]
      },
      // 省市区相关参数
      cityDistrictVillage: {
          disabled: false,
          cityId: "",
          provinceId: ''
      },
      dataList:{
          content:'库里的政策随机匹配6条'
      },
      // 表单数据
      form:{
        value1:'',
        value2:'',
        value3:'',
        value4:'',
        value5:'',
        proviceId:''
      },
      // 下拉选择数据
      select_data:{
        options1: [ {
          value: '1',
          label: '10万元以内'
        }, {
          value: '2',
          label: '10万元-100万元'
        },{
          value: '3',
          label: '100万元-300万元'
        }, {
          value: '4',
          label: '300万元-500万元'
        },{
          value: '5',
          label: '500万元-1000万元'
        }, {
          value: '6',
          label: '1000万元-3000万元'
        }, {
          value: '7',
          label: '3000万元-5000万元'
        }, {
          value: '8',
          label: '5000万元-1亿元'
        }, {
          value: '9',
          label: '1亿元以上'
        }],
        options2: [{
          value: '1',
          label: '10万元以内'
        }, {
          value: '2',
          label: '100万元-300万元'
        }, {
          value: '3',
          label: '300万元-500万元'
        },{
          value: '4',
          label: '500万元-1000万元'
        }, {
          value: '5',
          label: '1000万元-3000万元'
        }, {
          value: '6',
          label: '3000万元-5000万元'
        }, {
          value: '7',
          label: '5000万元-1亿元'
        }, {
          value: '8',
          label: '1亿-3亿元'
        }, {
          value: '9',
          label: '3亿-5亿元'
        }],
        options3: [{
          value: '1',
          label: '100万以内开票销售额'
        }, {
          value: '2',
          label: '100万-300万开票销售额'
        }, {
          value: '3',
          label: '300万-500万开票销售额'
        }, {
          value: '4',
          label: '500万-1000万开票销售额'
        },{
          value: '5',
          label: '1000万-3000万开票销售额'
        }, {
          value: '6',
          label: '3000万-5000万开票销售额'
        }, {
          value: '7',
          label: '5000万-1亿开票销售额'
        }, {
          value: '8',
          label: '1亿-3亿开票销售额'
        }, {
          value: '9',
          label: '3亿以上开票销售额'
        }],
        options4: [{
          value: '1',
          label: '历史融资额300万元以内'
        }, {
          value: '2',
          label: '历史融资额300万元-500万元'
        }, {
          value: '3',
          label: '历史融资额500万元-1000万元'
        }, {
          value: '4',
          label: '历史融资额1000万元-3000万元'
        }, {
          value: '5',
          label: '历史融资额3000万元-5000万元'
        }, {
          value: '6',
          label: '历史融资额5000万元-1亿元'
        }, {
          value: '7',
          label: '历史融资额1亿-2亿元'
        }, {
          value: '8',
          label: '历史融资额2亿元以上'
        }],
        options5: [{
          value: '1',
          label: '申请补贴'
        }, {
          value: '2',
          label: '开新公司'
        }, {
          value: '3',
          label: '分公司子公司落地'
        }, {
          value: '4',
          label: '当地业务开拓'
        },{
          value: '5',
          label: '公司迁址'
        }, {
          value: '6',
          label: '投资'
        }, {
          value: '7',
          label: '创业'
        }],
      },
      policyInfoPass:'', //一键匹配提交数据
      passShow: false, //匹配结果-是否显示
      infoList: [], //匹配信息列表
    };
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
          .then(response => {
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestSuccessTime", requestTime);
            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "success",
                requestResult: response.toString()
              }
            })
          })
          .catch(error=>{
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestErrorTime", requestTime);

            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "error",
                requestResult: error.toString()
              }
            })
          })
    },
// 点击展开新页面
    openLink(link){
      window.open(link);
    },
    // 一键匹配
    onSubmit(){
      this.$refs.form.validate((valid) => {
          if (valid) {
            const params = JSON.parse(JSON.stringify(this.form));
            this.policyInfoPass = params.value1 + '_' + params.value2 + '_' + params.value3 + '_' + params.value4 + '_' + params.proviceId + '_' + params.value5;
            this.querySubmit();
          } else {
            window.scroll(0, 400);
            return false;
          }
      });
    },
    // 匹配按钮 - 数据请求
    async querySubmit(){
      // 加载中
      const loading = Loading.service({
        background: "rgba(0, 0, 0, .2)"
      });
      const api = '/api/supplementary/policy/queryPolicyInfoList';
      const data = await this.$fetchData.fetchPost({policyInfoPass:this.policyInfoPass}, api, 'json');
      if (data.code === '200') {
        loading.close(); // 关闭加载
        this.infoList = data.result;
        Message('匹配成功');
        this.passShow = true;
      }else{
        loading.close(); // 关闭加载
        Message.error(data.message)
      }
    },
    /** 返回市区信息 */
    villageInfo(val) {
      let provice = val[0].id ? val[0].id : '';
      let city = val[1].id ? val[1].id : '';
      this.form.proviceId = provice +'_' + city;
      const params = {
          pageNum: 1,
          pageSize: 1000,
          provinceId: '',
          cityId: '',
      };
      if (!val[0]) {
          params.provinceId = val[0].id ? val[0].id : '';
      } else if (!val[1]) {
          params.cityId = val[1].id ? val[1].id : '';
      }
    },
    // 从新选择城市 清空proviceId
    proviceInfo(){
      this.form.proviceId = '';
    }
  },
};
</script>

<style lang="less" scoped>
.policymatch {
  .policymatch_content{
    width: 1010px;
    margin:0 auto 100px;
    .text-center{
      text-align: center;
    }
    .title{
        color: #265ae1;
        font-size: 30px;
        text-align: center;
        line-height: 100px;
        background-color: #fff;
    }
    .content {
        text-indent: 2em;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0px;
        color: #999999;
    }

    /deep/ .el-input__inner{
        width:580px;
    }
    /deep/ .el-input{
        width:580px;
    }

    .consultation{
        .newsList{
            margin-top:10px;
        }
        .newsTitle{
          margin-top: 50px;
          padding: 12px 0 6px;
          text-align: center;
          border-top: 1px dotted #d9d9d9;
        }
        li{
            color: #333333;
            font-size:16px;
            line-height:16px;
            margin-bottom: 20px;
            cursor: pointer;
            padding-right: 100px;
            position: relative;
          .newsData{
            position: absolute;
            font-size: 14px;
            color: #999;
            right: 0;
          }
        }
        li:hover{
            color:#3380fe;
            .newsData{
              color:#3380fe;
            }
        }
    }
  }
}
</style>
