<script>
/* COMPONENT DOCUMENT
 * desc: 2级联动 - 市区村地址选择
 */

import Vue from 'vue';
import {  Select, Option, Message } from 'element-ui';
Vue.use(Select);
Vue.use(Option);
export default {
  props: {
    cityDistrictVillage: {type: Object, default: () => {} }
  },
  watch: {
    cityDistrictVillage: {
      async handler(val) {
        if (val) {
          this.disabled = val.disabled;
          if (val.cityId){
            await this.getCityInfo('PROVINCE', '', false);
            await this.getCityInfo('CITY', val.proviceId, false);
          } else if (!val.cityId) {
             await this.getCityInfo('PROVINCE', '');
          }
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      provice: '',
      city: '',
      proviceList: [],
      cityList: [],
      message: '',
      val: []
    };
  },

  methods: {
    /** 获取市区村地址 */
    async getCityInfo(level, id, isEchoDisplay) {
      const api = this.$fetchApi.getCityList.api;
      const data = await this.$fetchData.fetchPost({ level, parentId: id }, api, 'json');
      if (data.code === '200') {
        if (level === 'PROVINCE') { // 市
          this.proviceList = data.result;
          if (this.cityDistrictVillage && this.cityDistrictVillage.provinceId && !isEchoDisplay) {
            data.result.forEach(item => {
              if (item.id === this.cityDistrictVillage.provinceId) {
                this.provice = item;
                this.val[0] = {
                  id: item.id,
                  name: item.name
                };
              }
            });
          }
        }  else
        if (level === 'CITY') { // 市
          this.cityList = data.result;
          if (this.cityDistrictVillage && this.cityDistrictVillage.cityId && !isEchoDisplay) {
            data.result.forEach(item => {
              if (item.id === this.cityDistrictVillage.cityId) {
                this.city = item;
                this.val[1] = {
                  id: item.id,
                  name: item.name
                };
              }
            });
            this.$emit('villageInfo', this.val);
          }
        }
      } else {
        // Message({
        //   showClose: true,
        //   message: data.message,
        //   type: 'error'
        // });
      }
    },
    // 选择时
    async choseProvince(e) {
      for (const index in this.proviceList) {
        if (e.id === this.proviceList[index].id) {
          await this.getCityInfo('CITY', this.proviceList[index].id, true);
          this.city = '';
          this.val[0] = {
            id: this.proviceList[index].id,
            name: this.proviceList[index].name
          };
        }
      }
      this.$emit('proviceInfo');
    },
    // 选择时
    async choseCity(e) {
      for (const index in this.cityList) {
        if (e.id === this.cityList[index].id) {
          this.val[1] = {
            id: this.cityList[index].id,
            name: this.cityList[index].name
          };
        }
      }
      this.$emit('villageInfo', this.val);
    }
  }
};
</script>

<template>
<div>
  <div>
    <el-select
      v-model="provice"
      value-key="id"
      @change="choseProvince" 
      placeholder="请选择省级地区">
      <el-option
        v-for="item in proviceList"
        :key="item.name"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
    <!-- <span style="margin-left: 10px;">城市列表</span> -->
  </div>
  <div style="margin-top:22px;">
    <el-select
      v-model="city"
      value-key="id"
      @change="choseCity" 
      placeholder="请选择市级地区">
      <el-option
        v-for="item in cityList"
        :key="item.name"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
  </div>
</div>
</template>
